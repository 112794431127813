html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}

html,
body {
    font: 16px/1.375 'PT Serif', sans-serif;
}

body {
    margin: 3.5em 0 0;

    @media screen and (min-width: $medium) {
        margin: 0;
    }
}

img,
svg {
    max-width: 100%;
    height: auto;
}

a {
    color: currentColor;
    text-decoration: none;
}

h1 {
    font-size: 26px;
    line-height: 1;
    font-weight: normal;
    margin: 0;
}

h2 {
    font-size: 26px;
    line-height: 1;
    font-weight: normal;
    margin: 0 0 1em 0;
}

h3 {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.2;
    margin: 0 0 2rem 0;

    h2 + & { margin-top: -1em; }
}

h4 {
    font-weight: normal;
    padding: normal;
    margin: normal;
}

.caption {
    font-size: 0.9em;
    font-style: italic;
    font-weight: normal;
    text-align: center;
    margin: 0 0 2em;
}

p { margin: 0 0 0.6em; }

.artist p a { text-decoration: underline; }

.section--text {
    ul {
        padding-left: 1em;
    
        li { padding-left: 1em; }
    }

    a { text-decoration: underline; }

    blockquote {
        padding: 0 0 0 2em;
        border-left: 1px solid;
        margin: 1em 0 2em;
        font-style: italic;

        blockquote {
            border: none;
            margin: 0;
            padding-left: 2em;
        }
    }
}
