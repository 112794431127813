%clean {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.artists {
    @extend %clean;
}

.artist {
    border-top: 1px solid;
    
    &.expanded { padding-bottom: 2em; }

    &:last-of-type { border-bottom: 1px solid; }

    &:not(.expanded) > *:not(.artist__title) {
        display: none;
    }

    &__title {
        position: relative;
        margin: 0.25em 0 0.5em;
        font-weight: bold;

        .expanded & {

            &::before { opacity: 1; }
        }

        .artist:not(.expanded) &:hover {
            cursor: pointer;

            &::after { opacity: 1; }
        }

        &::before,
        &::after {
            opacity: 0;
            transition: opacity 250ms;
        }

        &::after {
            content: '\2193';   // down arrow
            margin-left: 0.5em;
            color: rgba(black, 0.5);
        }

        &::before {
            content: '\2715';   // close 'x'
            display: block;
            position: absolute;
            top: 0.4rem;
            right: 1px;
            line-height: 1;
            color: rgba(black, 0.5);
        }
    }
}

.gallery {
    position: relative;
    margin: 2em auto 4em;
    height: 0;
    padding-bottom: 60vh;
    background: #f5f5f5;
    
    @media screen and (min-width: $medium) {
        padding-bottom: 80vh;
    }

    img { max-height: 100%; }

    &__wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__list {
        @extend %clean;

        height: 100%;
        width: 100%;

        // make sure the slider wrappers keep the relative height
        & > div,
        & > div > div { height: 100%; }
    }
    
    &__item {
        position: relative;
        height: 100%;
        width: 100%;
    }

    &__image {
        display: flex;
        justify-content: center;
        flex-flow: column;
        align-items: center;
        height: calc(100% - 2rem);
        padding: 1rem;
        width: calc(100% - 2rem);
        
        .gallery__list & { height: calc(100% - 5rem); }
    }

    &__caption {
        position: absolute;
        left: 50%;
        bottom: 0.5rem;
        height: 2rem;
        z-index: 10;
        font-size: 0.8em;
        font-style: italic;
        text-align: center;
        width: 50%;
        display: flex;
        line-height: 1.2;
        justify-content: center;
        align-items: center;

        transform: translateX(-50%);

        @media screen and (min-width: $medium) {
            height: 3rem;
        }
    }

    &__nav {
        position: absolute;
        left: 0;
        bottom: 0;
        width: calc(100% - 1em);
        padding: 0.5em;
        height: 1.5rem;
        display: flex;
        justify-content: space-between;
        
        @media screen and (min-width: $medium) {
            height: 3rem;
            width: calc(100% - 2em);
            padding: 0.5em 1em;
        }
    }

    &__button {
        line-height: inherit;
        font-weight: inherit;
        border: none;
        background: none;
        width: 6rem;
        max-width: 20%;
        display: block;
        padding: 0;

        &:hover { cursor: pointer; }
        &:focus { outline: none; }
    }

    .arrow {
        display: block;
        height: 1px;
        color: rgba(black, 0.5);
        background: currentColor;

        &::before,
        &::after {
            content: '';
            display: block;
            height: 1px;
            margin-top: -1px; // offset height, set in middle
            background: currentColor;
            width: 22%;
            transform-origin: left;
        }

        &::before { transform: rotate(45deg); }
        &::after { transform: rotate(-45deg); }

        &--next {
            transform: rotate(180deg);
        }
    }
}

.logos {
    padding: 1em 0;
    text-align: center;

    &__list {
        @extend %clean;

        display: flex;
        justify-content: flex-start;
        flex-flow: wrap;
        align-items: center;
    }

    &__item { margin: 0.5em; }
}