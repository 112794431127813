.banner {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    
    @media screen and (min-width: $medium) {
        height: 100%;
        width: 16em;
    }
    
    &__bar {
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em;
    }
}

.main-navigation {
    position: absolute;
    z-index: 1;
    background: white;
    transform: translateX(100%);
    transition: transform 250ms;
    padding: 1em;
    height: calc(100vh - 5.7em);
    width: calc(100% - 2em);
    overflow-Y: scroll;

    @media screen and (min-width: $medium) {
        overflow: hidden;
        transform: none;
        padding: 0 1em;
        height: calc(100% - 2em);
        width: auto;
    }

    .home &,
    &.is-expanded { transform: translateX(0); }
}

.menu {
    @extend %clean;

    display: flex;
    flex-flow: column;
    align-items: flex-end;
    text-align: right;

    @media screen and (min-width: $medium) {
        align-items: flex-start;
        text-align: left;
    }

    &__item {
        &.active,
        &:hover {
            text-decoration: underline;
        }

        &--separator { height: 1em; }
    }
}

.menu-toggle {
    background: none;
    border: none;
    appearance: none;
    padding: 0;
    transition: transform 250ms;


    &.is-active { transform: rotate(90deg); }
    .home & { display: none; }

    @media screen and (min-width: $medium) {
        display: none;
    }
}
