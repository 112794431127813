

.center { text-align: center; }
.right { text-align: right; }
.left { text-align: left; }

// Debugging
// body, main, .banner { outline: 1px solid; }

.section {
    max-width: 50em;
    margin-bottom: 3.1em;
}

.content {
    padding: 1em 1em 10vh 1em;

    @media screen and (min-width: $medium) {
        margin-left: 16em;
    }
}

.placeholder {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.video {
    margin: 4em auto;

    &__wrap {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-bottom: 56.25%;
        max-width: 100%;
        width: 46em;
        margin: 0 auto 1em;
    }

    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.pdf {
    margin: 2em auto 4em;
    text-align: center;
    
    &__wrap {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-bottom: 60vh;
        max-width: 100%;
        width: 36em;
        margin: 0 auto 1em;

        @media screen and (min-width: $medium) {
            padding-bottom: 80vh;
        }
    }

    &__frame {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}